import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'Login',
        component: () => import('@/views/LoginView.vue'),
        meta: {
            title: 'MJ国内版-登录',
            isAuth: false
        }
    },
    {
        path: '/app',
        component: () => import('@/components/MainLayout.vue'), // 使用共享布局组件
        children: [
            {
                path: 'gallery',
                name: 'Gallery',
                component: () => import('@/views/GalleryView.vue'),
                meta: {
                    title: 'MJ国内版-画廊',
                    isAuth: true
                }
            },
            {
                path: 'mj',
                name: 'Channels',
                component: () => import('@/views/ChannelsView.vue'),
                meta: {
                    title: 'MJ国内版-MJ',
                    isAuth: true
                }
            },
            {
                path: 'me',
                name: 'me',
                component: () => import('@/views/me/UserProfile.vue'),
                meta: {
                    title: 'MJ国内版-个人',
                    isAuth: true
                }
            },
            {
                path: 'membership',
                name: 'membership',
                component: () => import('@/views/membership/MemberShip.vue'),
                meta: {
                    title: 'MJ国内版-个人',
                    isAuth: true
                }
            }
        ]
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
